import React from "react"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import Title from "../components/Title"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import styles from "../css/mention.module.css"

export default function legal({ data }) {
  return (
    <Layout>
      <SEO title="Les mentions légales" />
      <StyledHero img={data.mentionBcg.childImageSharp.fluid} />
      <section className={styles.mention}>
        <Title title="mentions" subtitle="légales" />
        <div className={styles.center}>
          <article className={styles.info}>
            <h4>Editeur</h4>
            <p className={styles.desc}>
              Le Moulin de Gâteau - Marie Cappello - 289 le Moulin Gateau -
              18210 - Saint-Pierre-les-Etieux.
              <br />
              Responsable de la publication : Marie Cappello
              (marie.cappello@wanadoo.fr).
              <br />
              Création du site web :{" "}
              <a
                href="https://laurent-pollez.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                id Soluce
              </a>
              <br />
              Photographie : William Abenhaim (william.abenhaim@gmail.com)
            </p>
          </article>
          <article className={styles.info}>
            <h4>Hébergement</h4>
            <p className={styles.desc}>
              Netlify,{" "}
              <a
                href="https://www.netlify.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Netlify Privacy Policy
              </a>
            </p>
            <h4>Services</h4>
            <p className={styles.desc}>
              Le site a pour objet de fournir une information concernant les
              activités du Moulin de Gâteau. Le proprietaire du site s’efforce
              de fournir sur le site des informations aussi précises que
              possible. Toutefois, il ne pourra être tenue responsable des
              omissions, des inexactitudes et des carences dans la mise à jour,
              qu’elles soient de son fait ou du fait des tiers partenaires qui
              lui fournissent ces informations. Tous les informations proposées
              sur ce site sont données à titre indicatif, sont non exhaustives,
              et sont susceptibles d’évoluer. Elles sont données sous réserve de
              modifications ayant été apportées depuis leur mise en ligne.
            </p>
          </article>
          <article className={styles.info}>
            <h4>Propriété intellectuelle</h4>
            <p className={styles.desc}>
              Marie Cappello est propriétaire des droits de propriété
              intellectuelle ou détient les droits d’usage sur tous les éléments
              accessibles sur le site, notamment les textes, images, graphismes,
              logo. Toute reproduction, représentation, modification,
              publication, adaptation de tout ou partie des éléments du site,
              quel que soit le moyen ou le procédé utilisé, est interdite, sauf
              autorisation écrite préalable à l’email :
              marie.cappello@wanadoo.fr. Toute exploitation non autorisée du
              site ou de l’un quelconque des éléments qu’il contient sera
              considérée comme constitutive d’une contrefaçon et poursuivie
              conformément aux dispositions des articles L.335-2 et suivants du
              Code de Propriété Intellectuelle.
            </p>
          </article>
          <article className={styles.info}>
            <h4>Etablissement de liens hypertextes</h4>
            <p className={styles.desc}>
              Tout site public ou privé est autorisé à établir un lien
              hypertexte sur la page d’accueil du site. Le site utilisateur ne
              doit pas faire apparaître ce site comme étant le sien, ou en
              dénaturer les contenus.
            </p>
          </article>
          <article className={styles.info}>
            <h4>Cookies</h4>
            <p className={styles.desc}>
              L’utilisateur est informé que lors de ses visites sur ce site, un
              ou des cookies sont susceptible de s’installer automatiquement sur
              son ordinateur. Un cookie est un fichier de petite taille, qui ne
              permet pas l’identification de l’utilisateur, mais qui enregistre
              des informations relatives à la navigation d’un ordinateur sur un
              site. Les données ainsi obtenues visent à faciliter la navigation
              ultérieure sur le site, et ont également vocation à permettre
              diverses mesures de fréquentation. Le paramétrage du logiciel de
              navigation permet d’informer de la présence de cookie et
              éventuellement, de refuser de la manière décrite à l’adresse
              suivante : www.cnil.fr Le refus d’installation d’un cookie peut
              entraîner l’impossibilité d’accéder à certains services
            </p>
          </article>
          <article className={styles.info}>
            <h4>Données personnelles</h4>
            <p className={styles.desc}>
              Utilisateur : Internaute se connectant, utilisant le site susnommé
              : https://www.moulindegateau.fr. En France, les données
              personnelles sont notamment protégées par la loi n° 78-87 du 6
              janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L.
              226-13 du Code pénal et la Directive Européenne du 24 octobre
              1995. Le proprietaire de ce site ne collecte éventuellement des
              informations personnelles relatives à l’utilisateur que pour le
              besoin de certains services proposés par l'activité de Marie
              Cappello. L’utilisateur fournit ces informations en toute
              connaissance de cause, notamment lorsqu’il procède par lui-même à
              leur saisie. Il est alors précisé à l’utilisateur de ce site
              l’obligation ou non de fournir ces informations. Conformément aux
              dispositions des articles 38 et suivants de la loi 78-17 du 6
              janvier 1978 relative à l’informatique, aux fichiers et aux
              libertés, tout utilisateur dispose d’un droit d’accès, de
              rectification, de suppression et d’opposition aux données
              personnelles le concernant. Pour l’exercer, adressez votre demande
              par email à : marie.cappello@wanadoo.fr ou en effectuant sa
              demande écrite et signée, accompagnée d’une copie du titre
              d’identité avec signature du titulaire de la pièce, en précisant
              l’adresse à laquelle la réponse doit être envoyée. Aucune
              information personnelle de l’utilisateur du site n’est publiée à
              l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur
              un support quelconque à des tiers. D’une façon générale, vous
              pouvez visiter notre site sur Internet sans avoir à décliner votre
              identité et à fournir des informations personnelles vous
              concernant.
            </p>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    mentionBcg: file(relativePath: { eq: "mentionBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
